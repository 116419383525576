<template>
  <div>
    <form @submit.prevent="createPartner" class="mt-4">
      <div class="row">
        <div class="col-3">
          <div class="col-md mb-3">
            <label for="logo" class="form-label">Logo:</label>
            <input
              type="file"
              id="logo"
              @change="handleLogoUpload"
              class="form-control"
              accept="image/*"
            />
          </div>
          <div class="col-md mb-3">
            <img
              :src="partner.logoUrl"
              v-if="partner.logo"
              alt="Logo"
              class="img-thumbnail"
              style="max-width: 100px"
            />
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-md-4 mb-3">
              <label for="name" class="form-label">Name:</label>
              <input
                type="text"
                id="name"
                v-model="partner.name"
                class="form-control"
                required
              />
            </div>
            <div class="col-md-4 mb-3">
              <label for="email" class="form-label">Email:</label>
              <input
                type="email"
                id="email"
                v-model="partner.email"
                class="form-control"
                required
              />
            </div>
            <div class="col-md-4 mb-3">
              <label for="phone" class="form-label">Phone:</label>
              <input
                type="text"
                id="phone"
                v-model="partner.phone"
                class="form-control"
                required
              />
            </div>
            <div class="col-md-4 mb-3">
              <label for="website" class="form-label">Website:</label>
              <input
                type="text"
                id="website"
                v-model="partner.website"
                class="form-control"
                required
              />
            </div>

            <div class="col-md-4 mb-3">
              <label for="location" class="form-label">Location:</label>
              <select
                id="location"
                v-model="partner.location"
                class="form-select"
                required
              >
                <option value="nationaux">Nationaux</option>
                <option value="internationaux">Internationaux</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 mb-3">
          <button type="submit" class="btn btn-primary">Create Partner</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    //   partner: {
    //     name: "",
    //     email: "",
    //     phone: "",
    //     website: "",
    //     location: "nationaux",
    //     logo: null,
    //     logoUrl: null,
    //   },
    };
  },
  methods: {
    createPartner() {
      const formData = new FormData();
      formData.append("name", this.partner.name);
      formData.append("email", this.partner.email);
      formData.append("phone", this.partner.phone);
      formData.append("website", this.partner.website);
      formData.append("location", this.partner.location);
      formData.append("logo", this.partner.logo);

      this.$store.dispatch("partner/create", formData);
    },
    handleLogoUpload(event) {
      const file = event.target.files[0];
      this.partner.logo = file;
      this.partner.logoUrl = URL.createObjectURL(file); // Create a URL for the image
    },
  },
  beforeCreate() {
    this.$store.dispatch("partner/find", this.$route.params.id);
  },
  computed: {
    ...mapGetters("partner", {
      partner: "getPartner",
    }),
  },
};
</script>
